<template>
  <div>
    <el-breadcrumb v-if="cate == 99" class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/seniorityArticle' }"
        >资质文章</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb v-if="cate == 6" class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/scienceArticle' }"
        >医生科普</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb v-if="cate == 0" class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/articleManage' }"
        >文章管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form>
      <el-form-item label="封面图片" v-if="cate == 6">
        <upload :src.sync="fmpic"></upload>
      </el-form-item>
      <el-form-item label="文章标题">
        <el-input
          v-model="articleT"
          placeholder="请输入标题"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="所属医生" v-if="cate == 6">
        <div style="text-align: left">
          <div>
            <el-input
              size="small"
              placeholder="请输入精确的姓名或电话号"
              clearable
              prefix-icon="el-icon-search"
              v-model="search"
              style="width: 220px"
              @clear="clearsearch"
            >
            </el-input>
            <el-button
              size="small"
              type="primary"
              style="margin-left: 10px"
              @click="query"
              >查询</el-button
            >
          </div>
          <el-radio-group
            v-model="choicedoc"
            style="margin-top: 20px; margin-left: 70px"
          >
            <el-radio
              :label="item.id"
              v-for="item in doclist"
              style="width：180px;margin-bottom:10px;"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="文章详情"></el-form-item>
      <FullEditor id="a" rows="10" upload="/"></FullEditor>
      <div style="margin-top: 80px">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import FullEditor from "../components/FullEditor.vue";
import upload from "../components/upload";
export default {
  components: {
    FullEditor,
    upload,
  },
  data() {
    return {
      type: "",
      id: "",
      articleT: "",
      content: "",
      title: "添加文章",
      cate: "",
      search: "", //搜索医生
      doclist: [],
      choicedoc: "",
      fmpic: "", //医生科普封面图片
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    this.cate = this.$route.query.cate;
    if (this.$route.query.id) {
      //读取详情接口
      this.id = this.$route.query.id;
      this.axios.get("/gu/get_gu_article_by_id?id=" + this.id).then((res) => {
        if (res.data.code == 0) {
          this.articleT = res.data.result.title;
          if (res.data.result.content != "") {
            let txt = this.htmlDecode(res.data.result.content);
            this.$nextTick(() =>
              setTimeout(() => {
                tinymce.activeEditor.setContent(txt);
              }, 300)
            );
          }
          if (this.cate == 6) {
            this.search = res.data.result.doc_id;
            this.choicedoc = res.data.result.doc_id;
            this.fmpic = res.data.result.image;
          }
        } else {
          console.log(res);
        }
      });
    }
    if (this.type != "new") {
      this.title = "编辑文章";
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    query() {
      this.axios
        .post(
          "/gu/search_gu_docs",
          this.qs.stringify({ keyword: this.search, is_flag: "all" })
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.doclist = rs.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearsearch() {
      this.search = "";
      this.doclist = [];
    },
    sub() {
      let data = tinyMCE.activeEditor.getContent();
      let form = {
        title: this.articleT,
        content: this.htmlEncode(data),
        cate: this.cate,
        image: this.fmpic,
      };
      if (this.cate == 6) {
        if (this.choicedoc == "") {
          this.$message({
            message: "请选择医生",
            type: "error",
          });
          return;
        } else {
          form.doc_id = this.choicedoc;
        }
      }
      let url = "",
        str = "";
      if (this.type != "new") {
        form.id = this.id;
        url = "/gu/update_gu_article";
        str = "修改成功";
      } else {
        url = "/gu/insert_gu_article";
        str = "添加成功";
      }
      if (form.title == "") {
        this.$message({
          message: "请填写标题",
          type: "error",
        });
        return;
      }
      if (form.content == "") {
        this.$message({
          message: "请填写文章内容",
          type: "error",
        });
        return;
      }
      console.log(form);
      this.axios.post(url, this.qs.stringify(form)).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            message: str,
            type: "success",
          });

          this.$router.go(-1);
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    htmlEncode: function (html) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
      temp.textContent != undefined
        ? (temp.textContent = html)
        : (temp.innerText = html);
      //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
      var output = temp.innerHTML;
      temp = null;
      return output;
    },
    /*2.用浏览器内部转换器实现html解码（反转义）*/
    htmlDecode: function (text) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>
